

.cov_panel_container{
    padding: 20px 22px;
}
.journey_header{
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
}
.journey_events_main_div{
    height: 30px;
    border-radius: 15px;
    background-color: rgba(255, 134, 51, 0.4);
    position: absolute;
    padding: 0 17px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    right: 0;
}
.journey_header_color{
    color: #f47f34;
}
.journey_header_font_size{
    font-size: 20px;
    margin-right: 19px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #f47f34; */
    border-radius: 4px;
    cursor: pointer;
}
.journey_active_div{
    background-color:#f47f34;
    color: white;
}
.journey_legend_main_div{
    margin: 8px 110px;
    margin-bottom: 0px;
    margin-right: 0px;
    display: flex;
    height: auto;
    align-items: center;
    flex-wrap: wrap;
}
.journey_legend_heading_main_div{
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-right: 29px;
    margin-top: 10px;
}
.journey_legend_heading_main_div > img{
    margin-right: 9px;
}
/* .chartjs-render-monitor{
    height: 545px !important;
} */

#chart-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    border-radius: 6px;
    z-index: 2;
}

#chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white !important;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}
/* table, th, td {
  border: 1px solid black;
} */
.tbb{
    display: inline-block;
    border: 1px solid black;
}
.bx{
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.5),0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.sentiscore{
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
}
.journey_tree_main_div{
    height: auto;
    width:431px;
    position: relative;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5) !important;
    background-color: #f9f9f9 !important;
}
.journey_tooltip_header_main_div{
    background-color: white;
    padding: 0 20px;
    height: 50px;
    align-items: center;
    /* width: 100%; */
    border-bottom:solid 1px #cccccc;
    display: flex;
    font-family: 'Open Sans';
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.journey_data_maindiv{
    overflow: auto;
}
.journey_data_maindiv::-webkit-scrollbar {
    width: 6px;
    background-color: white;
}

/* Track */
.journey_data_maindiv::-webkit-scrollbar-track {
    border-radius:5px;
}
/* Handle */
.journey_data_maindiv::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 5px;
}
.journey_inner_div{
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative;
    cursor: pointer;
    border-bottom:solid 1px #cccccc;
}
.journey_tooltip_label{
    cursor: pointer;
    width: 100%;
    margin-right: 30px;
    font-size: 14px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.journey_tooltip_after{
    display: block;
    z-index: 2;
    line-height: 7px;
    bottom: -7px;
    border: solid transparent;
    content: " ";
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin-left: -13px;
}
.journey_carrot_shadow{
    font-size: 35px;
    color: white;
    /* box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.5); */ 
    text-shadow: 2px 1px 2px #b3aeae;
    z-index: 0;
    line-height: 7px;
    right: -14px;
    top:9px;
    /* bottom: -7px; */
    border: solid transparent;
    content: " ";
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin-left: -13px;
}
.popup_main_div{
    width: 600px;
    height: auto;
    border-radius: 6px;
    box-shadow: 0 6px 14px 3px rgba(0, 0, 0, 0.2);
}
.popup_header{
    background-color: #f9f9f9 !important;
    height: 50px;
    font-size: 15px;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0 31px;
    border-bottom: 1px solid #cccccc;
    /* border-radius: 0 !important; */
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    box-shadow: unset !important;
}
.popup_header_text{
    font-size: 15px !important;
    font-weight: bold !important;
    color: #000000;
    font-family: "Open Sans", sans-serif !important;
    margin-left: 9px !important;
    text-transform: capitalize;
}
.popup_header_logo{
  color: #2c6087;
    font-size: 20px;
    margin-right: 11px;
}
.popup_header_cancel{
  font-size: 20px;
  position: absolute;
  cursor: pointer;
  right: 31px;
}
.popup_footer_main_div{
  height: 50px;
  background-color: #f9f9f9;
  border-top: 1px solid #cccccc;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: flex-end;
}
.popup_footer_button{
  width: auto;
  height: 33px;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Open Sans", sans-serif !important;
}
.popup_footer_cancel_button{
  background-color: #d8d8d8 !important;
  color: #000000 !important;
}
.popup_footer_apply_button{
  background-color: #f47f34 !important;
  color: white !important;
  margin-left: 10px !important;
}
.popup_content__body{
  display: grid;
  grid-template-columns: 50% 50%;;
}
.popup_contnet_body_result_div{
    font-size: 15px;
    font-weight: 600;
    color: #2c6087;
    justify-content: flex-end !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    /* word-break: break-word; */
    -ms-hyphens: auto;
    text-align: right;
}
.popup_hgt_align_center{
    height: auto !important;
    /* align-items: center; */
    display: flex;
    position: relative;
    padding: 5px 0;
    word-break: break-word;
}
.popup_hgt_align_center > span {
    position: absolute;
    right: 0;
}
.popup_hgt_align_center > label {
    font-size: 15px;
    letter-spacing: normal;
    color: #000000;
}
.dialog-content{
    max-height: 550px !important;
    margin:0px 10px!important;
    width: 600px;
}

.dialog-content::-webkit-scrollbar {
    width: 6px;
    background-color: white;
    border-radius: 6px !important ;
}
.reoccurance_div{
    width: 24px;
    height: 24px;
    border-radius: 4px;
    position: absolute;
    /* background-color: #f47f34; */
    border: solid 1px #4a90e2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
/* Track */
.dialog-content::-webkit-scrollbar-track {
    border-radius:5px;
}
/* Handle */
.dialog-content::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 5px;
}
.circle_notification{
    width: 27px;
  height: 27px;
  border-radius: 50%;
  position: absolute;
  background-color: #f47f34;
  border: 4px solid #ffc6a1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.gradient_axis{
    width: 6px;
    position: absolute;
    height: 541px;
    z-index: 0;
    /* left: 0; */
    right: 52px;
    top: 7px;
    background-image: linear-gradient(to bottom, #46cc46, #7ad34a 13%, #f4a634 23%, #f47f34 32%, #f35a5a 42%, #f35a5a 52%, #e94747 63%, #e03434 72%, #e03434 81%, #d82323 90%, #c70000);
}
/* #line_canvas{
    height: 600px !important;

} */

.events-dropdown{

    display: flex;
    line-height: 50px;
    justify-content: space-evenly;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
}
#min,#max{

   margin-left: 5px; 
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
}
.hover-tooltip{
    width: fit-content;
    height: fit-content;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    align-items: center;
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    color: #000000;
    position: absolute;
    z-index: 0;
}
.hover-tooltip-after{
    font-size: 25px;
    position: absolute;
    bottom: -19px;
    color: white;
    left: 12px;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 1px 0px);
}
.journey_sentimeter_tooltip{
    width: 420px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}
.journey_dateBox_border{
    border-style: solid;
    border-width: 1px;
    padding: 3px;
}
.journey_zoom_back_btn{
    width: 50px;
    height: 22px;
    border-radius: 4px;
    position: absolute !important;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    /* margin-left: 170px !important; */
    margin-top: 14px !important; 
    width: 50px;
    min-width: 0px !important;
    align-items: center !important;
    padding: 0 !important;

}

.ribbon_img{
    position: absolute;
    left: -13px;
    top: 3px;
}
.ribbon_img_text{
    position: absolute;
    left: 0px;
    top: 10px;
    color: white;
    font-weight: 600;
    font-size: 12px;
}

.journey_zoom_back_btn > span{
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: capitalize;
}
.journey_from_date_div{
            position: relative;
            left: 170px;
            font-size: 14px;
            border-radius: 3px;
            border: solid 1px #cccccc;
            color: #333333;
            padding: 3px;
}
.journey_to_date_div{
    position: absolute;
    right: 12px;
    border: 1px solid black;
    padding: 5px;
    font-size: 14px;
    border-radius: 3px;
    border: solid 1px #cccccc;
    color: #333333;
    padding: 3px;
}
body {
    margin: 0;
  }
  section {
    margin: 0 auto; 
    max-width: 660px;
    padding: 0 20px;
  }
.journey_timeline_filter{
  font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
    text-align: end;
    justify-content: flex-end;
    display: flex;
    margin-top: 15px;
}
.journey_timeline_filter_date{
  margin-left:10;
  min-width:30px;
  width:fit-content;
  text-align:center;
  cursor:pointer
}

