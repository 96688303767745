.oneview_main_div{
  height: 80px;
  padding: 0 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  position: relative;
}
a{
    text-decoration: none;
}
.infocus_tooltip{
    font-size: 12px !important
}
.infocus_content_div{
    height: 357px;
    overflow-y: scroll;
}
.infocus_full_hgt_content_div{
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    height: 70px !important;
    line-height: 2 !important;
    -webkit-box-orient: vertical !important;
    color: white !important;
}
.infocus_info_content_div{
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    height: 50px;
    line-height: 2;
    -webkit-box-orient: vertical;
    color: white;
}
.infocus_content_div_without_scroll{
    height: fit-content;
}
.infocus_content_div::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff03;
}
.infocus_content_div::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 5px;
}
.infocus_content_div::-webkit-scrollbar-track {
    border-radius: 5px;
}
.oneview_user_main_div{
    width: calc(100vw - 722px);
    height: 50px;
    display: flex;
    align-items: center;
    margin: 15px 0px;
}
.oneview_user_logo_main_div{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: solid 1px #ffffff;
    background-color: #d8d8d8;
}
.oneview_user_name{
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.oneview_user_account_type{
    display: flex;
    align-items: center;
    margin-top: 4px;
}
.oneview_user_account_type_sign{
    width: 14px;
    height: 14px;
    border-radius: 25px;
}
.oneview_user_account_type_sign_gold{
    width: 14px;
    height: 14px;
    background-image: radial-gradient(top, #FEECA8 0%, #D8B85D 69.64%, #B18B20 100%);
    border-radius: 25px;
}
.oneview_user_account_type_sign_platinum{
    width: 14px;
    height: 14px;
    background-image: radial-gradient(top, #FFFFFF 0%, #EFDAAD 69.64%, #B4A789 100%);
    /* background-image: radial-gradient(180deg, #FFFFFF 0%, #EFDAAD 69.64%, #B4A789 100%) */
    border-radius: 25px;
}
.oneview_user_account_type_sign_silver{
    width: 14px;
    height: 14px;
    background-image: radial-gradient(top, #ffffff 0%, #666666 100%);
    border-radius: 25px;
}
.onewview_user_account_type_text{
    font-size: 12px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    /* letter-spacing: normal; */
    margin-left: 9px;
    color: #ffffff;
}
.infocus_btn{
    margin-left: auto;
    background-color: #0187cf;
    color: white;
    border: 0;
    border-radius: 6px;
    height: 24px;
    cursor: pointer;
    outline: unset;
    box-shadow: 1px 1px 3px #00000085;
}
.onewview_smile_notification_maindiv{
    /* width: 60px; */
    /* height: 71px; */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    right: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 8px 18px;
    cursor: pointer;
}
.oneview_emoji{
    font-size: 24px;
    color: #f4a634;
}
.oneview_notification_count{
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    margin-top: 3px;
}
.oneview_lightbiulb{
    display: flex;
    height: inherit;
    align-items: center;
    right: 80px;
    margin-left: 17px;
    margin-right: 7px;
    font-size: 28px;
    /* font-weight: normal; */
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    cursor: pointer;
}
.oneview_nav_tab_main_div{
    margin-left: auto;
    height: 100%;
    width: auto;
    right: 135px;
    display: flex;
    align-items: flex-end;
}
.oneview_nav_tab_div{
    width: auto;
    height: 40px;
    font-size: 14px !important;
    font-weight: bold !important;
    /* margin-left: 10px !important; */
    text-transform: capitalize !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    font-family: 'Open Sans', sans-serif !important;
}
.oneview_nav_active_tab_div{
    background-color: white !important;
    color: #000000 !important;
}

.truncate-overflow {
    overflow: hidden;
    color: white;
    width: inherit;
    position: relative;
    line-height: 32px;
    white-space: nowrap;
    margin-left: 23px;
    text-overflow: ellipsis;
    /* height: 60px; */
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
  }
  /* .truncate-overflow:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: inherit;
  }

.truncate-overflow::before {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
  } */
.oneview_customer_tooltip_info_div{
    position: absolute;
    background-color: #676565f0;
    margin-left: 23px;
    color: white;
    padding: 10px;
    font-size: 14px;
    border-radius: 6px;
    z-index: 4;
}
.oneview_customer_info_div{
    position: absolute;
    background-color: #676565f0;
    margin-left: 23px;
    color: white;
    padding: 10px;
    font-size: 14px;
    border-radius: 6px;
    z-index: 4;
    height: auto;
    max-height: 400px;
    overflow: auto;
}
.oneview_customer_info_div::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff03;
}
.oneview_customer_info_div::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 5px;
}
.oneview_customer_info_div::-webkit-scrollbar-track {
    border-radius: 5px;
}

.oneview_Sentemeter,.oneview_high_light{
    /* width: 420px; */
    height: 250px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
    background-color: #f9f9f9;
    position: absolute;
    top: 80px;
    right: 81px;
    border-radius: 5px;
    z-index: 1;
}

.oneview_Sentemeter::after{
    top: -20px;
    left: calc(25% + 69.5%);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    border-top: 20px solid transparent;
    border-left: 20px solid transparent;
    border-bottom: 20px solid white;
    border-width: 10px;
    margin-left: -13px;
}

.oneview_high_light::before{
    top: -20px;
    right:16px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    border-top: 20px solid transparent;
    border-left: 20px solid transparent;
    border-bottom: 20px solid white;
    border-width: 10px;
    margin-left: -13px;
}


.oneview_high_light_header{
    background-color: white;
    height: 50px;
    color: black;
    /* width: inherit; */
    border-bottom: solid 1px #ccc;
    padding-left: 18px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.oneview_infocus_header{
    background-color: white;
    height: 50px;
    color: black;
    /* width: inherit; */
    border-bottom: solid 1px #ccc;
    padding-left: 18px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.oneview_high_light_content{
    /* width: inherit; */
    border-bottom: solid 1px #ccc;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    height: 50px;
    font-size: 14px;
    color: #000000;
}

.oneview_focus_content{
    /* width: inherit; */
    border-bottom: solid 1px #ccc;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    height: 50px;
    cursor: pointer;
    font-size: 14px;
    color: #000000;
}

.oneview_high_light_innerDiv{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 100%;
    /* display: flex; */
    margin-left: 18px;
    margin-right: 18px;
    /* margin-bottom: 13px; */
    /* margin-top: 13px; */
    position: relative;
}
.oneview_sentimeter_popuop_content{
    /* width: inherit; */
    border-bottom: solid 1px #ccc;
    align-items: center;
    font-size: 14px;
    color: #000000;
}
.oneview_sentimeter_popuop_innerdiv{
    width: 100%;
    /* display: flex; */
     margin-left: 13px;
   /* margin-right: auto; */
    margin-bottom: 13px;
    margin-top: 13px;
    position: relative;
}
.oneview_high_light_count{
    /* position: absolute;
    right: 0; */
    font-weight: bold;
    float: right;
    margin-left: 20px;
}
.oneview_sentimeter_emoji_fontsize{
    font-size:30px;
    margin-right: 5px;
    align-items: center;
    transition: font-size 0.2s;
}
.oneview_sentimeter_emoji_fontsize_feedback{
    font-size:30px;
    margin-right: 5px;
    align-items: center;
    transition: font-size 0.2s;
    cursor: pointer;
}
/*.oneview_sentimeter_emoji_fontsize:hover{
    font-size: 50px;
}*/
.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 9px solid #46cc46;
    margin-top: 5px;
    margin-right: 6px;
}
.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 9px solid #f35a5a;
    margin-top: 5px;
    margin-right: 6px;
}
.oneview_sentimeter_width{
    width: 50%;
}
.oneview_sentimeter_main_div{
    position: absolute;
    right: 0;
}
.oneview_sentimeter_width :first-child{
    display: flex;
}
.oneview_sentimeter_drpdwn__header{
    font-weight: 600;
    color: #000000;
    font-size: 12px;
}
.oneview_sentimeter_drpdwn_info{
    color: #666666;
    font-size: 12px;
}
.oneview_sentimeter_edit_div{
    width: 100%;
    margin-left: auto;
    height: fit-content;
    margin-bottom: 20px;
    cursor: pointer;
}
.oneview_sentimeter_edit{
    width: fit-content;
    margin-left: auto;
    padding-right: 12px;
}
.oneview_sentimeter_edit .oneview_enter_score{
    display: none;
}
.oneview_sentimeter_edit:hover .oneview_enter_score{
    display: block;
}
.oneview_enter_score{
    position: absolute;
    width: fit-content;
    cursor: pointer;
    right: 0;
    font-size: 11px;
    background-color: white;
    padding-left: 21px;
    /* color: #000000; */
    padding-right: 21px;
    padding-top: 13px;
    padding-bottom: 13px;
    bottom: -29px;
    border-radius: 4px;
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.3);
}
.oneview_enter_score_carret{
    position: absolute;
    top: -15px;
    color: white;
    right: 12px;
    width: 20px !important;
    height: 23px;
}
.oneview_sentimeter_hover_tooltip{
    position: absolute;
    font-size: 10px;
    top: 21px;
    color: #000000;
    font-weight: 600;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 30px 0 rgba(0,0,0,0.3);
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.3);
    padding: 14px;
    background-color: #FFFFFF !important;
}
.oneview_range_score_carret{
    position: absolute;
    /* top: -15px; */
    bottom: -13px;
    color: white;
    width: 20px !important;
    height: 23px;
}
.oneview_sentimeter_feedback_button{
    background-color: #0087cf !important;
    color: white !important;
}
.oneview_sentimeter_emoji_backgroud_unhappy:hover,.oneview_sentimeter_emoji_backgroud_unhappy_active{
    background-color: #f7cfcf;
}
.oneview_sentimeter_emoji_backgroud_neutral:hover,.oneview_sentimeter_emoji_backgroud_neutral_active{
    background-color: #ece9bc;
}
.oneview_sentimeter_emoji_backgroud_happy:hover,.oneview_sentimeter_emoji_backgroud_happy_active{
    background-color: #c0eec0;
}
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  /* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 5px;
    padding: 1px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 60%;
    margin-left: -40px;
    font-size: 13px;
    font-family: sans-serif;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
  }
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    /*border-style: solid; */
    border: 0.5px solid;
    border-color: #f8f6f6 transparent transparent transparent;
  }
  .position-box{
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
  }
  div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}
.commentCursor{
    cursor: pointer; 
    color:#0087cf;
}
/* .oneview_high_light_content > div{
    display: flex;
    align-items: center
} */