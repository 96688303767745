
.bullet_div_main_div{
    align-items: center;
    display: flex;
    width: 40px;
}
.bullet_div{
    border-radius: 50%;
    margin-right: 16px;
}
.bullet_color{
    background-image: radial-gradient(circle at 50% 0, #9cdd9c, #52ab52);
}
.bullet_red_color{
    background-image: radial-gradient(circle at 50% 0, #e58282, #d15959);
}
.bullet_div_hgt_wdt{
    width: 24px;
    height: 24px;
    position: relative;
}
.sub_bullet_div_hgt_wdt{
    width: 12px;
    height: 12px;
}
.panel_header_text_div{
    font-size: 14px;
    font-weight: 600;
    width: auto;
    max-width: 832px;
    margin-right: 201px;
    display: flex;
    align-items: center;
}
.panel_notification_div{
    /* width: 175px; */
    height: 40px;
    border-radius: 4px;
    border: solid 1px #eeeeee;
    background-color: #ffffff;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 0 16px;
}
.panel_details_text_main_div{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
}
.panel_details_text_div{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #333333;
}
.panel_notification_subdiv{
    display: flex;
    align-items: center;
    margin-right: 24px;
}
.panel_notification_subdiv_count{
    font-size: 14px;
    font-weight: 600;
}
.prediction_disposition_head{
    border-radius: 4px;
    /* display: flex; */
    /* align-items: center; */
    padding: 20px 21px;
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 12px
}
.prediction_new_main_div{
    /* box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.06); */
    background-color: #ffffff;
    display: flex;
    border-radius: 4px;
    border: solid 1px #cccccc;
}
.prections_table_heading{
    font-size: 12px;
    line-height: 31px;
    font-weight: bold;
    color: #666666;
    padding-left: 20px;
    padding-right: 20px;
    background-color:#ffffff;
    border-bottom: solid 1px #cccccc;
    border-top-left-radius: 5px;
}
.prediction_data_main_div{
    display: flex;
    align-items: center;
    min-height: 78px;
    height: auto;
    font-size: 14px;
    color: #333333;
    background-color: #f9f9f9;
    border-bottom: solid 1px #cccccc;
    padding-left: 23px;
    padding-right: 10px;
    cursor: pointer;
}
.predictions_div{
    background-color: #f9f9f9;
    overflow-x: hidden;
    overflow-y: auto;
    /* border-right: solid 1px #cccccc; */
}
.predictions_div::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

/* Track */
.predictions_div::-webkit-scrollbar-track {
    border-radius:5px;
}
/* Handle */
.predictions_div::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 5px;
}
.predictions_active_tab{
    background-color: white;
    border-right: solid 1px white;
    font-weight: 600;
}
.predictions_rating_div{
    text-transform: uppercase;
    width: 44px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: 48px;
    top: -9px;
    position: relative;
    margin-left: auto;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 8px 7px;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
}
.prediction_justifiction_display{
    display: none;
}
.prediction_justification_common_class{
    overflow-y: scroll;
}
.prediction_justification_common_class::-webkit-scrollbar {
    width: 6px;
    background-color: white;
}

/* Track */
.prediction_justification_common_class::-webkit-scrollbar-track {
    border-radius:5px;
}
/* Handle */
.prediction_justification_common_class::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 5px;
}
.prediction_justification_count{
    display: flex;
    height: 40px;
    padding-left: 20px;
    border-bottom: solid 1px #cccccc;
}
.prediction_cout_total_icon{
    color: #999999;
    font-size: 12px;
    margin-right: 9px;
    margin-top: 1px;
}
.prediction_tooltip{
    position: absolute;
    left: -4px;
    width: max-content;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #cccccc;
    background-color: #ffffff;
    border-radius: 4px;
    font-size: 12px;
    font-weight: normal;
    padding: 10px;
    top: 23px;
    display: none;
}
.prediction_tooltipSign{
    text-transform: uppercase;
    font-weight: bold;
}
.prediction_triangle{
    width: 23px;
    height: 10px;
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: 2px;
}
.prediction_triangle:after {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    background: #ffffff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 5px;
    left: 6px;
    -webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.5);
    border: solid 1px #cccccc;
}
    
.bullet_div_hgt_wdt:hover > .prediction_tooltip {
    display: block;
}
.prediction_neutral{
    border: solid 1px #666666;
    background-color: #999999;
}
.prediction_positive{
    border: solid 1px #5bb15b;
    background-color: #6bbc6b;
}
.prediction_negative{
    border: solid 1px #923636;
    background-color: #e07878;
}
.prediction_smile_hgt_wdt{
    font-size: 30px;
    margin-bottom: 6px;
}
.prediction_desposition_positive{
    color: #7ad34a;
}
.prediction_desposition_neutral{
    color: #f5a623;
}
.prediction_desposition_negative{
    color: #de7373;
}
.prediction_likedislike{
    height: 65px;
    width: 30px;
    /* border: 1px solid #CCCCCC; */
    margin-left: auto;
    border-radius: 3px;
    background-color: #FFFFFF;
}
.prediction_dislike_child_div{
    height: 1px;
    background-color: #CCCCCC;
}
.prediction-thmbs{
    display: flex;
    height: 31px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
}
.prediction-thmbs-up{
    color: #999999;
    border-top-left-radius:3px;
    border: 1px solid #CCCCCC;
    border-bottom: 0px;
    border-top-right-radius:3px;
}
.prediction-active-thmbs-up{
    color: #FFFFFF;
    border-top-left-radius:3px;
    border: 1px solid #299629;
    background-color: #6BBC6B;
    border-bottom: 0px;
    border-top-right-radius:3px;
}
.prediction-thmbs-down{
    color: #999999;
    border: 1px solid #CCCCCC;
    border-top: 0px;
    border-bottom-left-radius:3px;
    border-bottom-right-radius:3px;
}
.prediction-active-thmbs-down{
    color: #FFFFFF;
    border: 1px solid #D84040;
    background-color: #DE7373;
    border-top: 0px;
    border-bottom-left-radius:3px;
    border-bottom-right-radius:3px;
}
.disableClick
{
  cursor: default;
  opacity: 0.7;
}