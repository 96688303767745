.card_header_line_main_div{
    width: inherit;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.card_header_line_div{
    width: 100px;
    background-color: white;
    height: 7px;
    border-radius: 5px;
    margin-top: 19px;
    margin-bottom: 19px;
}
.card_header_line_smile_div{
    width: 50px;
    /* height: 64px; */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    border-bottom-left-radius: 4px;
    position: absolute;
    top: -1px;
    right: 10px;
    border-bottom-right-radius: 4px;
    text-align: center;
    padding-top:8px;
    padding-bottom: 6px; 
}
.card_header_line_smile{
    font-size: 24px;
}
.card_header_line_smile_count{
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    margin-top: 3px;
}